import React from 'react';
import { graphql } from 'gatsby';
import Link from 'gatsby-link';
import Template from './../../layouts/Template';
import { AboutSection, SkillsSection } from './_Styles';

const About = ({ data }) => (
	<Template title={`About | ${data.site.siteMetadata.title}`} desc="About me">
		<div className="wrapper">
			<AboutSection>
				<div className="container">
					<div className="box">
						<header className="title-big">
							<h1>About Sophia</h1>
						</header>
						<div className="row">
							<div className="sophia__headshot">
								<img src="/sophiazey.jpg" width="100%" />
							</div>
							<div className="sophia__rightside">
								<div>
									<p>
										Sophia Zey currently works full-time as a Front End Developer at Top Floor Tech. She also has a selective
										private studio of piano and voice students. Read more about her musical background here:{' '}
										<Link to="/music">Music</Link>.
									</p>
									<p>Sophia currently lives in Oconomowoc, WI with her husband Tyler and two dogs.</p>
								</div>
								<SkillsSection>
									<h2>Skills</h2>
									<h5>Languages</h5>
									<div className="skills">
										<div className="skill">
											<a href="https://www.w3schools.com/html/html5_intro.asp/" target="_blank">
												<div className="skill__btn">#html5</div>
											</a>
										</div>
										<div className="skill">
											<a href="https://developer.mozilla.org/en-US/docs/Web/CSS/CSS3/" target="_blank">
												<div className="skill__btn">#css3</div>
											</a>
										</div>
										<div className="skill">
											<a href="https://sass-lang.com/" target="_blank">
												<div className="skill__btn">#scss</div>
											</a>
										</div>
										<div className="skill">
											<a href="https://www.javascript.com/" target="_blank">
												<div className="skill__btn">#javascript</div>
											</a>
										</div>
										<div className="skill">
											<a href="http://www.php.net/" target="_blank">
												<div className="skill__btn">#php</div>
											</a>
										</div>
									</div>
									<h5>Frameworks</h5>
									<div className="skills">
										<div className="skill">
											<a href="https://www.gatsbyjs.com" target="_blank">
												<div className="skill__btn">#gatsby</div>
											</a>
										</div>

										<div className="skill">
											<a href="https://reactjs.org/" target="_blank">
												<div className="skill__btn">#react</div>
											</a>
										</div>
										<div className="skill">
											<a href="https://getbootstrap.com/" target="_blank">
												<div className="skill__btn">#bootstrap</div>
											</a>
										</div>
										<div className="skill">
											<a href="https://bulma.io/" target="_blank">
												<div className="skill__btn">#bulma</div>
											</a>
										</div>
										<div className="skill">
											<a href="https://tailwindcss.com" target="_blank">
												<div className="skill__btn">#tailwind</div>
											</a>
										</div>
										<div className="skill">
											<a href="https://foundation.zurb.com/" target="_blank">
												<div className="skill__btn">#foundation</div>
											</a>
										</div>
									</div>
									<h5>Other</h5>
									<div className="skills">
										<div className="skill">
											<a href="https://www.cloudflare.com/" target="_blank">
												<div className="skill__btn">#cloudflare</div>
											</a>
										</div>
										<div className="skill">
											<a href="https://nodejs.org/en/" target="_blank">
												<div className="skill__btn">#nodejs</div>
											</a>
										</div>
										<div className="skill">
											<a href="https://gulpjs.com/" target="_blank">
												<div className="skill__btn">#gulp</div>
											</a>
										</div>
									</div>
									<h5>Design </h5>
									<div className="skills">
										<div className="skill">
											<a href="https://stayintech.com/UX" target="_blank">
												<div className="skill__btn">#ux</div>
											</a>
										</div>
										<div className="skill">
											<a href="https://en.wikipedia.org/wiki/User_interface_design" target="_blank">
												<div className="skill__btn">#ui</div>
											</a>
										</div>
										<div className="skill">
											<a href="https://www.w3schools.com/html/html_responsive.asp" target="_blank">
												<div className="skill__btn">#responsive</div>
											</a>
										</div>
										<div className="skill">
											<a href="https://a11yproject.com/checklist" target="_blank">
												<div className="skill__btn">#accessibility</div>
											</a>
										</div>
										<div className="skill">
											<a href="https://www.invisionapp.com/" target="_blank">
												<div className="skill__btn">#webdesign</div>
											</a>
										</div>
										<div className="skill">
											<a href="https://www.invisionapp.com/" target="_blank">
												<div className="skill__btn">#prototypes</div>
											</a>
										</div>
										<div className="skill">
											<a href="https://www.invisionapp.com/" target="_blank">
												<div className="skill__btn">#wireframes</div>
											</a>
										</div>
									</div>

									<h5>Tools</h5>
									<div className="skills">
										<div className="skill">
											<a href="https://www.sketchapp.com/" target="_blank">
												<div className="skill__btn">#sketch</div>
											</a>
										</div>
										<div className="skill">
											<a href="https://www.invisionapp.com/" target="_blank">
												<div className="skill__btn">#invisionapp</div>
											</a>
										</div>
										<div className="skill">
											<a href="https://helpx.adobe.com/xd/how-to/what-is-xd.html" target="_blank">
												<div className="skill__btn">#adobexd</div>
											</a>
										</div>
										<div className="skill">
											<a href="https://www.figma.com/" target="_blank">
												<div className="skill__btn">#figma</div>
											</a>
										</div>
									</div>
								</SkillsSection>
							</div>
						</div>
					</div>
				</div>
			</AboutSection>
		</div>
	</Template>
);

export default About;

export const pageQuery = graphql`
	query AboutQuery {
		site {
			siteMetadata {
				title
			}
		}
	}
`;
