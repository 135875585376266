import styled from 'styled-components';

export const AboutSection = styled.section`
	${tw`pt-24`};
	.box {
		${tw`text-left`};
	}
	.title-big {
		${tw`text-center sm:text-128`};
		top: -0.55em;
		margin-bottom: -0.35em;
	}
	.sophia {
		&__headshot {
			${tw`w-full md:w-1/3 mb-8 md:mb-0`};
			img {
				${tw`rounded-full `};
				width: 200px;
				height: 200px;
				object-fit: cover;
				@media (min-width: 576px) {
					width: 278px;
					height: 278px;
				}
			}
		}
		&__rightside {
			${tw` w-full md:w-2/3`};
		}
	}
	p {
		${tw`mt-3`};
	}

	a {
		border-bottom: 3px solid #f8ff00;
		transition: ease all 0.2s;
		&:hover {
			background: #f8ff00;
			color: #004664;
		}
	}
`;

export const SkillsSection = styled.section`
	h5 {
		${tw`mt-8 mb-4`};
	}
	.skills {
		${tw`flex flex-wrap`};
	}
	.skill {
		${tw` mr-4 mb-4`};
		&__btn {
			${tw`text-18 sm:text-18 font-heavy px-5 pt-2 pb-1 text-grey-darker bg-red-lightest rounded-full`};
			transition: ease all 0.2s;
		}
		&:hover {
			.skill__btn {
				${tw`bg-red-light text-white`};
			}
		}
	}
	.skills:nth-of-type(2) {
		.skill {
			&__btn {
				${tw`bg-orange-lightest`};
			}
			&:hover {
				.skill__btn {
					${tw`bg-orange`};
				}
			}
		}
	}
	.skills:nth-of-type(3) {
		.skill {
			&__btn {
				${tw`bg-yellow-lightest`};
			}
			&:hover {
				.skill__btn {
					${tw`bg-yellow text-grey-darker`};
				}
			}
		}
	}
	.skills:nth-of-type(4) {
		.skill {
			&__btn {
				${tw`bg-green-lightest`};
			}
			&:hover {
				.skill__btn {
					${tw`bg-green`};
				}
			}
		}
	}
	.skills:nth-of-type(5) {
		.skill {
			&__btn {
				${tw`bg-blue-gray-light`};
			}
			&:hover {
				.skill__btn {
					${tw`bg-blue-gray`};
				}
			}
		}
	}
	.skills:nth-of-type(6) {
		.skill {
			&__btn {
				${tw`bg-purple-lightest`};
			}
			&:hover {
				.skill__btn {
					${tw`bg-purple-light`};
				}
			}
		}
	}
`;
